import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
export default {
  name: "OperationLog",
  data: function data() {
    return {
      // 筛选
      filter: [{
        placeholder: '请求结果',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '成功'
        }, {
          id: '0',
          name: '失败'
        }]
      }, {
        placeholder: '',
        value: '',
        type: 'data',
        list: []
      }, {
        placeholder: '操作名称',
        value: '',
        type: 'text',
        list: []
      }],
      // 登录记录/日常记录tab
      activeName: '1',
      // table列表接口传递参数
      postListData: {
        logType: '',
        pageSize: '',
        pageIndex: '',
        name: '',
        responseResult: '',
        startDate: '',
        endDate: ''
      },
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20 //每页个数
    };
  },
  created: function created() {
    //获取数据
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.postListData.logType = parseInt(this.activeName);
      this.postListData.pageSize = this.pageSize;
      this.postListData.pageIndex = this.currentPage;
      this.service.axiosUtils({
        requestName: 'getAccountLog',
        data: this.postListData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this.tableData = res.data.data;
            _this.total = res.data.total;
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    // 登录记录/日常记录切换
    handleTabClick: function handleTabClick(tab) {
      var _this2 = this;
      this.activeName = tab.name;
      this.currentPage = 1;
      this.$nextTick(function () {
        _this2.initData();
      });
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // 筛选按钮函数
    search: function search(val) {
      var that = this;
      if (this.$common.checkSearchOrAllFilter(val)) {
        var filterData = val;
        console.log(val);
        this.postListData.responseResult = filterData[0].value;
        this.postListData.startDate = filterData[1].value[0];
        this.postListData.endDate = filterData[1].value[1];
        this.postListData.name = filterData[2].value;
      } else {
        this.postListData.responseResult = '';
        this.postListData.startDate = '';
        this.postListData.endDate = '';
        this.postListData.name = '';
      }
      that.initData();
    },
    reload: function reload() {
      for (var i = 0; i < this.filter.length; i++) {
        this.filter[i].value = '';
      }
      this.postListData.responseResult = '';
      this.postListData.startDate = '';
      this.postListData.endDate = '';
      this.postListData.name = '';
      this.currentPage = 1;
      this.initData();
    }
  }
};